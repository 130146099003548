:root {
	--primary-color: #006b53;
	--primary-color--darker: #0f5546;
	--dark-color: black;
  --light-color: white;
  --gold : #f4a024;
  --back-dark : #f7f8fa;
}

.footer-container{
    margin-top: -20px;
    padding: 80px 0px 80px 0px;
    background-color: #1f1f1f;

}
.title-text{
    color: #72726a
}

.desc-text{
    color: white;
}