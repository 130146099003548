:root {
	--primary-color: #006b53;
	--primary-color--darker: #0f5546;
	--dark-color: black;
  --light-color: white;
  --gold : #f4a024;
}


.reg-container{
    /* width: 100% !important; */
    padding-top: 160px;
    padding-bottom: 80px;
    background: url('./../assets/images/back-reg.png');
    /* height: 500px; */
    background-position: center;
    background-size: 120% 120%;
    background-repeat: no-repeat;
}

@media (max-width : 567px){
  .reg-container{
    background: #006b53;
  }
}


/* div{
    border: 1px solid red
} */

.top-info{
  /* border: 1px solid black; */
  background-color: white;
}
.cont-small{
  background-color: #eee
}

.top-info .icon-user  {
  color: rgb(187, 179, 179);
}

.reg-success-container {
  padding-top: 120px;
  padding-bottom: 120px;
}


.text-reg{
  text-align: center;
  color: #707070
}

.headline-reg-suc{
  font-size: 59px;
}

.head-reg-suc-2{
  font-size: 20px;
}

.head-reg-suc-3{
  font-size: 16px;
}
.icon-img{
  text-align: center;
}

.link-reg-suc{
  text-decoration: underline;
  cursor: pointer;
  color: #474747
}