:root {
	--primary-color: #006b53;
	--primary-color--darker: #0f5546;
	--dark-color: black;
  --light-color: white;
  --gold : #f4a024;
}

@import url('https://fonts.googleapis.com/css?family=Squada+One&display=swap');

/* div{
  border: 1px solid red;
} */



.home-jumbotron{
  width: 100%;
  height: 90vh;
  background-color: var(--primary-color);
  padding-top: 90px;
  
}

.jumbotron-text{
  padding: 20px 0px 20px;
  /* border: 1px solid red; */
  text-align: center;
  font-size: 12vw;
  font-weight: bolder;
  width: 100%;
  color: var(--light-color);
  line-height: 65px
}
.jumbotron-second-text{
  text-align: center;

  /* border: 1px solid red; */
  width: 100%;
  font-size: 5vw;
  color: var(--light-color);
  padding-left: 4px;
  font-weight: lighter
}

.jumbotron-btn{
  /* margin: 40px 0px 40px ; */
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--light-color);
  font-size: 14px;
  color: black;
  border-radius: 48px;
  padding-left: 4px;
  margin-top: 30px;
  cursor: pointer;
  letter-spacing: 2px;
  transition: 500ms opacity
}

.jumbotron-btn:hover{
  opacity: 0.92;
}

.jumbotron-btn-second{
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  font-size: 14px;
  color: #ffffff;
  border:2px solid #ffffff;
  border-radius: 48px;
  padding-left: 4px;
  margin-top: 15px;
  cursor: pointer;
  letter-spacing: 2px;
  
}

.jumbotron-btn-second:hover{
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--light-color);
  font-size: 14px;
  color: black;
  border: 0.5px solid #ffffff;
  border-radius: 48px;
  padding-left: 4px;
  margin-top: 15px;
  cursor: pointer;
  letter-spacing: 2px;
  -webkit-transition: background-color 100ms linear;
  -webkit-transition: color 100ms linear;
  -ms-transition: background-color 100ms linear;
  -ms-transition: color 100ms linear;
  transition: background-color 100ms linear;
  transition: color 100ms linear;
}
.hashtag{
  color: var(--gold);
  font-size: 5vw;
  margin-top: 10px;
  font-weight: bolder
}

/*   ======================== END JUMBOTRON ====================== */


/*   ======================== NAVBARR ====================== */
.my-navbar{
  background-color: rgba(0, 0, 0, 0.9);
  border-bottom: 1px solid var(--gold)
  /* font-size: 40px; */
}


/*   ======================== END NAVBARR ====================== */


/*   ======================== CAROUSEL JUMBOTRON ====================== */
.carousel-jumbotron{
  display: none;
  width: 36vw;
  /* position: absolute;
  top: 140px;
  right: 110px; */

}

.carousel-image-container{
  width: 500px;
  height: 300px
}


.carousel-image{
  width: 100% !important;
  height: 100%;
  object-fit: cover;
  
}

/*   ======================== END CAROUSEL JUMBOTRON ====================== */



/*   ======================== SOCMED JUMBOTRON ====================== */


.carousel-socmed-container{
  display: flex;
  justify-content: center;
  width: 80%;
  margin: auto;
  margin-top: 30px;
  justify-content: space-between;
}
.carousel-socmed-container div{
    height: 40px;
    width: 40px;
    border-radius: 40px;
    background-color: var(--primary-color--darker);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
}
.carousel-socmed-container div:hover{
  color: var(--gold)
}
































@media only screen and (min-width: 600px) {
  .home-jumbotron{
    height: 110vh;
  }

  .jumbotron-text{
    line-height: 100px
  }
  /* For tablets: */
  

}
@media only screen and (min-width: 768px) {
  /* For desktop: */


  /*   ======================== CAROUSEL ====================== */
  .carousel-image-container{
    width: 100%;
    height: 600px;
  }
  
  
  .carousel-image{
    width: 100%;
    height: 100%;
    object-fit: cover
  }
  
  .carousel-caption-my{
    /* margin-bottom: 50px; */
    width: 630px;
    position: absolute;
    left: 5%;
    top: 100px;
    text-align: start;
    font-size: 80px;
    color: var(--light-color);
    font-weight: bolder;
    opacity: 0.9;
  }

  /*   ======================== END CAROUSEL ====================== */


  /*   ======================== JUMBOTRON ====================== */


  .home-jumbotron{
    width: 100%;
    height: 100vh;
    background-color: var(--primary-color);
    padding-top: 140px;
    padding-left: 105px;
    
  }
  .jumbotron-text{
    padding: 0px;
    text-align: left;
    /* border: 1px solid red; */
    font-size: 5vw;
    font-weight: bolder;
    width: 40vw;
    color: var(--light-color);
    line-height: 92px
  }
  .jumbotron-second-text{
    text-align: left;

    /* border: 1px solid red; */
    width: 40vw;
    font-size: 2vw;
    color: var(--light-color);
    padding-left: 4px;
    font-weight: lighter
  }

  .jumbotron-btn{
    width: 24vw;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--light-color);
    font-size: 14px;
    color: black;
    border-radius: 48px;
    padding-left: 4px;
    margin-top: 30px;
    cursor: pointer;
    letter-spacing: 2px;
    transition: 500ms opacity
  }

  .jumbotron-btn:hover{
    opacity: 0.92;
  }

  .jumbotron-btn-second{
    width: 24vw;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    font-size: 14px;
    color: #ffffff;
    border: 2px solid #ffffff !important;
    border-radius: 48px;
    padding-left: 4px;
    margin-top: 15px;
    cursor: pointer;
    letter-spacing: 2px;
    
  }

  .jumbotron-btn-second:hover{
    width: 24vw;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--light-color);
    font-size: 14px;
    color: black;
    border: 0.5px solid #ffffff;
    border-radius: 48px;
    padding-left: 4px;
    margin-top: 15px;
    cursor: pointer;
    letter-spacing: 2px;
    -webkit-transition: background-color 100ms linear;
    -webkit-transition: color 100ms linear;
    -ms-transition: background-color 100ms linear;
    -ms-transition: color 100ms linear;
    transition: background-color 100ms linear;
    transition: color 100ms linear;
  }
  .hashtag{
    color: var(--gold);
    font-size: 20px;
    margin-top: 10px;
    font-weight: bolder
  }

  /*   ======================== END JUMBOTRON ====================== */
  
  
  /*   ======================== NAVBARR ====================== */
  .my-navbar{
    padding-left: 109px;
    padding-right: 109px;
    background-color: rgba(0, 0, 0, 0.9);
    border-bottom: 1px solid var(--gold)
    /* font-size: 40px; */
  }
  .brand{
    font-size: 30px;
    font-weight: bolder;
    
  }

  /*   ======================== END NAVBARR ====================== */


  /*   ======================== CAROUSEL JUMBOTRON ====================== */
  .carousel-jumbotron{
    display: block;
    width: 36vw;
    /* position: absolute;
    top: 140px;
    right: 110px; */

  }

  .carousel-image-container{
    width: 100%;
    height: 300px
  }
  
  
  .carousel-image{
    width: 100%;
    height: 100%;
    object-fit: cover
  }

  /*   ======================== CAROUSEL JUMBOTRON END ====================== */



  /*   ======================== SOCMED ICON ====================== */
  .carousel-socmed-container{
  margin-top: 0px;

    display: flex;
    justify-content: center;
    width: 36vw;
    /* position: absolute;
    top: 480px;
    right: 109px; */
    justify-content: space-between;
    position: relative;
    right: 51px;
  }
  .carousel-socmed-container div{
      height: 40px;
      width: 40px;
      border-radius: 40px;
      background-color: var(--primary-color--darker);
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      cursor: pointer;
  }
  .carousel-socmed-container div:hover{
    color: var(--gold)
  }


}






















