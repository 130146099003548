/* div{
    border: 1px solid red
} */

:root {
	--primary-color: #006b53;
	--primary-color--darker: #0f5546;
	--dark-color: black;
  --light-color: white;
  --gold : #f4a024;
}
.container-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0px 80px
}

.text-section{
    color: white;
}

.circle{
    background-color: white;
    font-size: 14px;
    padding: 15px 50px 15px 50px;
    border-radius: 30px
}

.table-basic{
    padding: 80px 0px 80px;
}
.table-scroll{
    height: 400px;
    overflow-y: scroll;
}

.center{
    text-align: center;
    margin-bottom: 30px;
}

.Mycontainer{
    margin: 0px 40px 0px 40px;
    
}

.font-50{
    font-size: 30px;
}
.font-30{
    font-size: 16px;
}

.my-tab-item{
    width: 50%;
    text-align: center;
    color: var(--primary-color--darker);
}


.yt-section{
    padding: 60px 0px 60px;
    display: flex;
    flex-direction: column;
    align-items: center;

}


.video-yt{
    width: 100%;
    height: 272px;
}

.judul-yt{
    font-size: 21px;
    color: #666666;
    font-weight: bold;
}
.deskripsi-yt{
    color: #727272;
    font-size: 16px;
    font-weight: lighter;
}
.yt-btn{
    background-color: var(--primary-color--darker);
    color: white;
}

.tanggal-yt{
    font-size: 13px;
    font-weight: bold;
    color: var(--primary-color)
}

@media only screen and (min-width: 600px) {
    /* Tablet */
    .font-50{
        font-size: 40px;
    }
    .font-30{
        font-size: 20px;
    }

}

@media only screen and (min-width: 768px) {
    .font-50{
        font-size: 50px;
    }
    .font-30{
        font-size: 30px;
    }
    .yt-section{
        padding: 80px 0px 80px;
    }
    .video-yt{
        /* width: 720px;
        height: 400px; */
        flex: 1.3;
        height: 420px
    }

    .text-yt{
        flex: 1;
        position: relative;
        bottom: 30px
    }
    .yt-section{
        padding: 120px 0px 120px;
        display: flex;
        flex-direction: row;
        /* align-items: flex-start; */

    }
    .judul-yt{
        font-size: 36px
    }
    /* .yt-btn{
        align-self: flex-end;
    } */
    .yt-hide{
        display: none;
    }
    /* Desktop */
}

